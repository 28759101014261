import { Link } from "gatsby"
import React from "react"
import { withStyles } from "@material-ui/core"

function DrawerLink({ classes, to, children, isGatsbyLink }) {
  if (isGatsbyLink)
    return (
      <div className={classes.drawerLinkContainer}>
        <Link
          to={to}
          className={classes.link}
          activeClassName={classes.activeLinkStyle}
        >
          {children}
        </Link>
      </div>
    )

  return (
    <div className={classes.drawerLinkContainer}>
      <a
        href={to}
        className={classes.link}
        activeClassName={classes.activeLinkStyle}
      >
        {children}
      </a>
    </div>
  )
}

const styles = theme => ({
  link: {
    padding: "5px 10px",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "25px",
    color: theme.palette.text.white,
    textDecoration: "none",
    borderRadius: "8px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  drawerLinkContainer: {
    "&:not(:last-child)": {
      marginBottom: "20px",
    },
  },
  activeLinkStyle: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
})

// eslint-disable-next-line
DrawerLink = withStyles(styles)(DrawerLink)

export { DrawerLink }
