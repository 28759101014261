import React from "react"
import { withStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { scroller } from "react-scroll"

function CallToActionButton(props) {
  const {
    classes,
    desktopText,
    mobileText,
    withText = false,
    text = "",
    scrollTo="footerCallToActionBlock"
  } = props

  const containerClasses = withText
    ? `${classes.buttonContainer} ${classes.containerWithText}`
    : classes.buttonContainer

  return (
    <div className={containerClasses}>
      {withText && <p className={classes.textStyle}>{text}</p>}
      <Button className={classes.buttonStyle} onClick={handleClick}>
        {renderTextForButton()}
      </Button>
    </div>
  )

  function scrollToCallToActionBlock() {
    scroller.scrollTo(scrollTo, {
      duration: 400,
      delay: 100,
      smooth: true,
      offset: -100,
    })
  }

  function renderTextForButton() {
    return (
      <>
        <span className={classes.desktopButtonText}>{desktopText}</span>
        <span className={classes.mobileButtonText}>{mobileText}</span>
      </>
    )
  }

  function handleClick() {
    if (!withText) return scrollToCallToActionBlock()

    openForm()
  }

  function openForm() {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScvOuYjg2OaomMJd8JwkG_ieyhUIWAtDvScUPq1fxnhnaQBkw/viewform?usp=sf_link",
      "_blank"
    )
  }
}

const styles = theme => {
  return {
    ...theme.animations.fadeIn,
    ...theme.animations.zoomIn,
    buttonStyle: {
      minWidth: "186px",
      letterSpacing: 0,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    buttonContainer: {
      opacity: 0,
      animationDelay: ".55s",
      animation: "$fadein .35s ease-in-out forwards",
      marginTop: 80,

      [theme.breakpoints.down("sm")]: {
        marginTop: "53px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "8px",
      },
    },
    containerWithText: {
      display: "flex",
      width: "max-content",
      margin: "80px 0 0",
      padding: "10px 10px 10px 20px",
      alignItems: "center",
      borderRadius: "16px",
      backgroundColor: "rgba(16, 10, 35, 0.3)",

      [theme.breakpoints.down("sm")]: {
        marginTop: "19px",
      },
      [`@media screen and (max-width: 668px)`]: {
        boxSizing: "border-box",
        maxWidth: "480px",
        padding: "20px 15px",
        flexDirection: "column",
        justifyContent: "center",

        "& p": {
          margin: "0 0 20px 0",
          textAlign: "center",
        },
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    mobileButtonText: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    desktopButtonText: {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    saasDisableAnimation: {
      "&:before": {
        animation: "none",
      },
    },
    textStyle: {
      marginRight: "30px",
      fontSize: "18px",
      fontWeight: 700,
      letterSpacing: "-0.5px",
      color: "#FFFFFF",
    },
  }
}

// eslint-disable-next-line
CallToActionButton = withStyles(styles)(CallToActionButton)

export { CallToActionButton }
