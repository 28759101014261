import React, { forwardRef } from "react"
import { withStyles } from "@material-ui/core"

let ContentContainer = forwardRef((props, ref) => {
  const { classes, customClass = '', children } = props

  return (
    <div className={customClass} ref={ref}>
      <div className={classes.container}>
        {children}
      </div>
    </div>
  )
})

const styles = theme => ({
  container: {
    maxWidth: 1170,
    padding: "100px 40px",
    margin: "0 auto",
    position: "relative",

    [theme.breakpoints.down("md")]: {
      padding: "100px 40px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 20px",
    }
  },
})

// eslint-disable-next-line
ContentContainer = withStyles(styles)(ContentContainer)

export { ContentContainer }
