import React, { useState } from "react"
import { TelegramLink } from "../svg/telegram-link"

import { CustomLink } from "../atoms/custom-link"
import { Constants } from "../../constants"
import { withStyles } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { HeaderDrawer } from "../moleculs/header-drawer"
import { ContactUsButton } from "../moleculs/contact-us-button"
import { LogoMobile } from "../svg/logo-mobile"
import { Logo } from "../svg/logo"
import { Link } from "gatsby"
import { useTheme } from "@material-ui/core/styles"

function Navigation({ linkColor, classes, isHeader, isDarkLogo = false }) {
  const [isDrawerOpen, setDrawerIsOpen] = useState(false)

  const theme = useTheme()

  const containerClasses = isHeader
    ? classes.navigationContainer
    : `${classes.navigationContainer} ${classes.footer}`

  return (
    <div className={containerClasses}>
      <div>{renderLogo()}</div>
      {renderNavigation()}
      {renderDrawer()}
    </div>
  )

  function renderLogo() {
    if (!isHeader) return
    return (
      <Link to="/">
        <Logo isDark={isDarkLogo} className={classes.desktopLogo} />
        <LogoMobile className={classes.mobileLogo} />
      </Link>
    )
  }

  function renderDrawer() {
    return (
      <HeaderDrawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerIsOpen(false)}
        onOpen={() => setDrawerIsOpen(true)}
      />
    )
  }

  function renderMobileMenu() {
    if (!isHeader) return
    return (
      <MenuIcon
        onClick={() => setDrawerIsOpen(true)}
        className={classes.mobileMenu}
      />
    )
  }

  function renderSocial() {
    if (!isHeader) return null
    return (
      <div className={classes.social}>
        <TelegramLink
          className={classes.socialIcon}
          color={linkColor}
        />
      </div>
    )
  }

  function renderContactButton() {
    if (!isHeader) return null

    return <ContactUsButton/>
  }

  function renderNavigation() {
    return (
      <>
        {renderMobileMenu()}
        <div className={classes.linkContainer}>
          {renderNavigationLinks()}
          {renderContactButton()}
        </div>
      </>
    )
  }

  function renderNavigationLinks() {
    const { navigationLinks } = Constants
    return navigationLinks.map((linkItem, index) => (
      <CustomLink key={index} {...linkItem} linkColor={linkColor} />
    ))
  }
}

const styles = theme => {
  return {
    linkContainer: {
      position: "relative",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        display: ({ isHeader }) => (isHeader ? "none" : "flex"),
      },
      [`@media screen and (max-width: 767px)`]: {
        display: ({ isHeader }) => (isHeader ? "none" : "flex"),
        flexDirection: "column",
      },
    },
    mobileMenu: {
      color: ({ linkColor }) =>
        linkColor ? "#4D4D4D" : "#FFFFFF",
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
    desktopMenu: {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        display: ({ isHeader }) => (isHeader ? "none" : "block"),
      },
    },
    mobileLogo: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: ({ isHeader }) => (isHeader ? "block" : "none"),
      },
    },
    desktopLogo: {
      display: "block",
      [theme.breakpoints.down("sm")]: {
        display: ({ isHeader }) => (isHeader ? "none" : "block"),
      },
    },
    social: {
      display: "flex",
    },
    socialIcon: {
      display: "flex",
      alignItems: "center",
      marginRight: "20px",
      padding: "7px",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: ({ linkColor }) =>
          linkColor ? "rgba(16, 10, 35, 0.05)" : "rgba(255, 255, 255, 0.1)",
      },
      cursor: "pointer",
    },
    navigationContainer: {
      padding: ({ isHeader }) => (isHeader ? "30px 40px" : "0"),
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: ({ isHeader }) => (isHeader ? "20px" : "0"),
      },
    },
    footer: {
      alignItems: "flex-start",
      ...theme.defaultHorizonContainer,
    },
  }
}

// eslint-disable-next-line
Navigation = withStyles(styles)(Navigation)

export { Navigation }
