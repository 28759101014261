import { Link } from "gatsby"
import React from "react"
import { withStyles } from "@material-ui/core"

function CustomLink({ classes, to, children, isGatsbyLink }) {
  const path = typeof window !== "undefined" ? window.location.pathname : ""

  if (isGatsbyLink)
    return (
      <Link
        to={to}
        className={classes.link}
        activeClassName={classes.activeLinkStyle}
        partiallyActive={path === "/frontend"}
      >
        {children}
      </Link>
    )

  return (
    <a href={to} className={classes.link}>
      {children}
    </a>
  )
}

const styles = theme => ({
  link: {
    padding: "5px 10px",
    color: ({ linkColor }) =>
      linkColor ? linkColor : theme.palette.text.white,
    fontSize: "18px",
    fontFamily: "Europa, sans-serif",
    textDecoration: "none",
    fontWeight: "400",
    lineHeight: "1.35",
    backgroundColor: "transparent",
    whiteSpace: "nowrap",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: ({ linkColor }) =>
        linkColor ? "rgba(16, 10, 35, 0.05)" : "rgba(255, 255, 255, 0.1)",
    },
    "&:not(:last-child)": {
      marginRight: "10px",
    },

    [`@media screen and (max-width: 767px)`]: {
      "&:not(:last-child)": {
        marginRight: "0px",
        marginBottom: "20px",
      },
    },
  },
  activeLinkStyle: {
    backgroundColor: ({ linkColor }) =>
      linkColor ? "rgba(16, 10, 35, 0.1)" : "rgba(255, 255, 255, 0.1)",
    "&:hover": {
      opacity: "1",
    },
  },
})

// eslint-disable-next-line
CustomLink = withStyles(styles)(CustomLink)

export { CustomLink }
